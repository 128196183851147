<template>
  <div
    class="modal fade"
    :id="`cropperModal${id}`"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-mobile-fullscreen"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            Carica la tua immagine
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :id="`cropperModal${id}_closex`"
            @click="$emit('closeModal')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Cropper
            v-if="isOpenModal && id && (image.src || imageUrl)"
            ref="cropperHtmlElRef"
            class="cropper mx-auto"
            :stencil-component="stencilV2"
            :src="id && !image.src ? imageUrl + `?t=${timestamp}` : image.src"
            @ready="maximizeStencilTimeout"
            :stencil-props="{
              aspectRatio: 4 / 5,
            }"
            :size-restrictions-algorithm="pixelsRestriction"
          />
          <!-- :size-restrictions-algorithm="pixelsRestriction" -->
          <!---->
          <div
            v-else
            class="text-center cropper w-100 d-flex d-flex align-items-center justify-content-center"
          >
            <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
            <span class="text-gray-700 ps-3 fs-2 align-middle">
              Caricare un immagine
            </span>
          </div>

          <button
            type="button"
            class="btn btn-light text-gray-700 text-center mt-6 w-100 fs-5"
            @click="$refs.loadFile.click()"
          >
            <i class="bi bi-upload fs-6 text-gray-700"></i>carica una foto da
            ritagliare
          </button>

          <div class="row">
            <div class="col">
              <div class="d-flex">
                <button
                  type="button"
                  class="btn btn-light text-gray-700 text-center mt-6 w-100 fs-5"
                  title="Aumenta lo zoom (Shift+Plus)"
                  @click="zoom(true)"
                  @keydown="handleKeyDown"
                >
                  <i class="bi bi-zoom-in fs-4"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-light text-gray-700 text-center mt-6 w-100 fs-5 ms-1"
                  title="Diminuisci lo zoom (Shift+Minus)"
                  @click="zoom(false)"
                  @keydown="handleKeyDown"
                >
                  <i class="bi bi-zoom-out fs-4"></i>
                </button>
              </div>
            </div>
          </div>

          <input
            type="file"
            ref="loadFile"
            @change="loadImage($event)"
            accept="image/png, image/jpeg, image/jpeg"
            style="display: none"
          />
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn bg-light-success text-success rounded"
            @click="emitEventoRitaglio()"
          >
            INSERISCI
          </button>
          <button
            type="button"
            class="btn bg-light-warning text-warning rounded"
            @click="resetCropperPosition"
          >
            RESET
          </button>
          <button
            type="button"
            class="btn btn-light-danger text-danger"
            data-bs-dismiss="modal"
            aria-label="Close"
            :id="`cropperModal${id}_close`"
            @click="$emit('closeModal')"
          >
            CHIUDI
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper, RectangleStencil } from "vue-advanced-cropper";
import CircleStencilV2 from "./CircleStencil.vue";
import "vue-advanced-cropper/dist/style.css";
import { useCropper } from "@/composables/composableCropper";
import { watch, ref } from "vue";
import { alertFailed } from "@/functions/swAlert";
import moment from "moment";

export default {
  props: {
    id: {
      type: [Number, String],
    },
    imageUrl: {
      type: String,
    },
    isOpenModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["fileRitagliato"],
  components: {
    Cropper,
  },
  setup(props, { emit }) {
    const {
      crop,
      reset,
      loadImage,
      image,
      cropperHtmlElRef,
      downloadImage,
      setFileToSend,
      file,
      flip,
      rotate,
      zoom,
      resetCropperPosition,
      centerStencil,
      maximizeStencil,
      removeGarbage,
      resetImage,
      cropV2,
      maximizeStencilTimeout,
    } = useCropper();

    // watch(cropperHtmlElRef, () => {
    //   const elementi = document.getElementsByClassName(
    //     "massimizza_area_taglio"
    //   );

    //   setTimeout(() => {
    //     elementi[0].click();
    //   }, 500);
    // });

    const loadFile = ref(null);

    const emitEventoRitaglio = async () => {
      try {
        const fileCreated = await cropV2();
        console.log("fileCreated", fileCreated, file.value);
        if (!fileCreated) {
          if (!props.id) {
            alertFailed("Inserire un file!");
            return;
          }
        }
        document.getElementById(`cropperModal${props.id}_close`).click();
        emit("fileRitagliato", file.value);
        resetImage();
      } catch (error) {
        // Gestisci gli errori qui
        alertFailed("Errore nell'inserimento del file!");
        console.error(error);
      }
    };

    const rerender = ref(false);
    watch(
      () => props.id,
      () =>
        setTimeout(() => {
          rerender.value = true;
        }, 500)
    );

    const pixelsRestriction = (imageData, width, height) => {
      return {
        width: 260,
        height: 325,
      };
    };

    return {
      pixelsRestriction,
      rerender,
      emitEventoRitaglio,
      loadFile,
      crop,
      reset,
      loadImage,
      image,
      cropperHtmlElRef,
      downloadImage,
      setFileToSend,
      file,
      flip,
      rotate,
      zoom,
      resetCropperPosition,
      centerStencil,
      maximizeStencil,
      removeGarbage,
      resetImage,
      stencilV2: RectangleStencil,
      maximizeStencilTimeout,
      timestamp: moment().unix(),
    };
  },
};
</script>
<style scoped>
.modal.fade .modal-dialog {
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog {
  transform: translateY(0);
}
.cropper {
  background: #ddd;
}
@media (max-width: 768px) {
  .modal-mobile-fullscreen {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
  }

  .modal-mobile-fullscreen .modal-content {
    height: auto;
    border-radius: 0;
  }
  .cropper {
    height: 600px;
    width: 600px;
  }
}
</style>
